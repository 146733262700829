import React from "react";

import { useProfile } from "../../../hooks/useSelctOptions";

import CountryChart from "./CountryChart";
import Cards from "./Cards";
import ResellersRegistration from "./ResellersRegistration";
import SubResellersRegistration from "./SubResellersRegistration";
import Payment from "./Payment";
import Credits from "./Credits";
import ByStatusDevices from "./ByStatusDevices";
import ByPlatformsDevices from "./ByPlatformsDevices";
import DevicesRegistration from "./DevicesRegistration";

import AdminsActionsStatistics from "./AdminsActionsStatistics";

export default function ProvidersAnalytics() {
  const profile = useProfile();

  return (
    <div className="page-analytics">
      <Cards />
      <CountryChart />

      <div className="chart-list">
        <Payment />
        <Credits />
        <ResellersRegistration />
        <SubResellersRegistration />
        <ByStatusDevices />
        <DevicesRegistration />
        <ByPlatformsDevices />
        {profile?.permission_level && <AdminsActionsStatistics />}
      </div>
    </div>
  );
}
