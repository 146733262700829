import React, { useState } from "react";
import { Drawer, Form, Button, notification, Input } from "antd";
import Request from "../../Requests";

const defaultValues = {
  email: "",
  password: "",
  name: "",
  surname: "",
};

function CreatingAdminsDrawer({ visible, onClose, getData }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (data) => {
    setLoading(true);
    Request.admins
      .post(data)
      .then(() => {
        getData();
        setLoading(false);
        hideDrawer(true);
        notification.success({ message: "Admin created successfully" });
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };

  const hideDrawer = (changed) => {
    onClose(changed);
    form.resetFields();
  };

  return (
    <Drawer
      title="Create Admin"
      placement="right"
      onClose={() => hideDrawer(false)}
      open={visible}
      width={600}
    >
      <Form
        name="create-admin"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={defaultValues}
      >
        <Form.Item
          label="Email"
          name="email"
          autoComplete="off"
          rules={[
            {
              required: true,
              message: "Please input your email",
            },
            {
              type: "email",
              message: "Please input valid email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },

            {
              min: 8,
              message: "Password must be minimum 8 characters",
            },
          ]}
        >
          <Input.Password
            placeholder="Password"
            autoComplete="new-password"
            visibilityToggle={{ visible: true }}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
          style={{ marginBottom: 10 }}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Surname"
          name="surname"
          rules={[{ required: true, message: "Please input the surname!" }]}
          style={{ marginBottom: 10 }}
        >
          <Input placeholder="Surname" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default CreatingAdminsDrawer;
