import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Button,
  Radio,
  Select,
  notification,
  InputNumber,
  Tooltip,
} from "antd";
import Request from "../../../Requests";

import {
  PlusOutlined,
  WarningOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import PartnerPackagesDrawer from "../../../pages/partner-packages/PartnerPackagesDrawer";
import CreditDrawer from "./CreditDrawer";

export default function ResellerTypeDrawer({
  open,
  onClose,
  providerInfo,
  getProviderInfo,
  form,
  partnerPackagesList,
  getOptionsPartnerPackages,
  currentPackg,
  setPartnerPackageId,
  profile,
}) {
  const [loading, setLoading] = useState(false);
  const [addNewPackage, setAddNewPackage] = useState(false);
  const [credit, setCredit] = useState({
    show: false,
    type: "",
  });
  const [disabledRes, setDisabledRes] = useState(false);
  const [disabledPartner, setDisabledPartner] = useState(false);

  useEffect(() => {
    if (providerInfo?.is_partner && providerInfo?.is_seller) {
      form.setFields([
        {
          name: "reseller_type",
          value: "is_seller",
        },
      ]);
      setDisabledRes(true);
      setDisabledPartner(true);
    } else if (providerInfo?.is_partner) {
      form.setFields([
        {
          name: "reseller_type",
          value: "is_partner",
        },
      ]);
      setDisabledRes(true);
    } else {
      form.setFields([
        {
          name: "reseller_type",
          value: "is_reseller",
        },
      ]);
    }
  }, [providerInfo]);

  const onFinish = (values) => {
    const resellerType = {
      id: Number(providerInfo?.id),
    };

    if (
      values.partner_package_id &&
      providerInfo.partner_package_id !== values.partner_package_id
    ) {
      resellerType.partner_package_id = Number(values.partner_package_id);
    }
    // else if (currentPackg?.id) {
    //   resellerType.partner_package_id = Number(currentPackg?.id);
    // }

    if (values.reseller_type == "is_reseller") {
      resellerType.is_partner = false;
      resellerType.is_seller = false;
    }

    if (values.reseller_type == "is_partner") {
      resellerType.is_partner = true;
      resellerType.is_seller = false;
    }

    if (values.reseller_type == "is_seller") {
      resellerType.is_partner = true;
      resellerType.is_seller = true;
    }

    Request.provider_change_status(resellerType)
      .then(() => {
        setLoading(false);
        notification.success({ description: "Reseller type updated!" });
        getProviderInfo();
        setPartnerPackageId(resellerType?.partner_package_id);
        onClose();
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };

  return (
    <>
      <Drawer
        title="Change Reseller types"
        open={open}
        onClose={onClose}
        width={500}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="reseller_type" label="Reseller types">
            <Radio.Group style={{ display: "flex" }}>
              <Radio value="is_reseller" disabled={disabledRes}>
                Reseller
              </Radio>
              <Radio value="is_partner" disabled={disabledPartner}>
                Partner
              </Radio>
              <Radio value="is_seller">Seller</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const { reseller_type } = form.getFieldsValue();
              return (
                reseller_type == "is_reseller" &&
                profile?.permission?.manage_credits && (
                  <Form.Item label="Credit">
                    <InputNumber
                      placeholder="Add credit"
                      controls={false}
                      value={providerInfo?.credit}
                      disabled
                      addonAfter={
                        <Tooltip title="Add credit">
                          <PlusOutlined
                            onClick={() => {
                              setCredit({
                                show: true,
                                type: "add",
                              });
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "18px",
                              color: "#258ffb",
                              padding: 0,
                            }}
                          />
                        </Tooltip>
                      }
                      addonBefore={
                        <Tooltip title="Take credit">
                          <MinusOutlined
                            onClick={() => {
                              setCredit({
                                show: true,
                                type: "take",
                              });
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "18px",
                              color: "red",
                              padding: 0,
                            }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          {!providerInfo?.is_partner && !currentPackg?.id && (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { reseller_type } = form.getFieldsValue();
                return (
                  reseller_type !== "is_reseller" && (
                    <>
                      <div className="h-86 res-info-partner_package_id">
                        <Form.Item
                          name="partner_package_id"
                          label="Packages"
                          rules={[
                            {
                              required: true,
                              message: "Please select package",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <Select
                            options={partnerPackagesList}
                            showSearch
                            placeholder="Search by package"
                            optionFilterProp="label"
                            style={{ maxWidth: "350px" }}
                          />
                        </Form.Item>

                        <Button
                          onClick={() => setAddNewPackage(true)}
                          icon={<PlusOutlined />}
                          type="primary"
                          size="larg"
                          className="mt-5 btn-res-info-partner_package_id"
                        />
                      </div>

                      {currentPackg?.archived && (
                        <p style={{ color: "#7c5509", fontSize: "12px" }}>
                          <WarningOutlined /> This package archived!
                        </p>
                      )}
                    </>
                  )
                );
              }}
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
              style={{ marginTop: "10px" }}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <PartnerPackagesDrawer
        visible={addNewPackage}
        onClose={() => setAddNewPackage(false)}
        isGetOptions={true}
        getOptionsPartnerPackages={getOptionsPartnerPackages}
      />

      {profile?.permission?.manage_credits && (
        <CreditDrawer
          open={credit}
          onClose={() =>
            setCredit({
              show: false,
              type: "",
            })
          }
          providerId={providerInfo.id}
          getProviderInfo={getProviderInfo}
          currentCredit={providerInfo.credit}
        />
      )}
    </>
  );
}
