import { useEffect, useState } from "react";
import {
  Form,
  Button,
  notification,
  Input,
  Tooltip,
  Select,
  Modal,
  Space,
  Switch,
} from "antd";
import {
  TransactionOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  RightOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { createFieldsValue } from "../../../Utils";

import Request from "../../../Requests";

import { useCountries } from "../../../hooks/useSelctOptions";

import { returnGeneratePassword, copyText } from "../../../configs/config";

import {
  ConfirmComponent,
  TableText,
  TableDateHHMMSS,
} from "../../../components";

import ResellerInfoDrawer from "../ResellerInfoDrawer";
import ResellerTypeDrawer from "./ResellerTypeDrawer";

export default function ResellerInfo({
  theProviderId,
  providerInfo,
  getProviderInfo,
  form,
  setDeactivatedAllDevices,
  setGetResellers,
}) {
  const [formResellerType] = Form.useForm();

  const countriesOptions = useCountries();

  const [blockReseller, setBlockReseller] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [onParentId, setOnParentId] = useState(false);

  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState(null);

  const [createdByAdmin, setCreatedByAdmin] = useState(null);

  const [resellerType, setResellerType] = useState("");
  const [isResellerTypeDrawer, setIsResellerTypeDrawer] = useState(false);
  const [partnerPackagesList, setPartnerPackagesList] = useState([]);
  const [currentPackg, setCurrentPackg] = useState(null);
  const [partnerPackageId, setPartnerPackageId] = useState(
    providerInfo?.partner_package_id
  );

  useEffect(() => {
    getProfile();
    getAdminsData();
  }, []);

  useEffect(() => {
    getOptionsPartnerPackages();
  }, [partnerPackageId]);

  useEffect(() => {
    if (providerInfo && theProviderId) {
      setBlockReseller(providerInfo?.blocked);
      form.setFieldsValue(createFieldsValue(providerInfo, fields));
    } else {
      form.resetFields();
    }

    if (!providerInfo.is_partner && !providerInfo.is_seller) {
      setResellerType("Reseller");
    }
    if (providerInfo.is_partner && !providerInfo.is_seller) {
      setResellerType("Partner");
    }
    if (providerInfo.is_partner && providerInfo.is_seller) {
      setResellerType("Seller");
    }
    setPartnerPackageId(providerInfo?.partner_package_id);
  }, [providerInfo, theProviderId]);

  const getAdminsData = () => {
    Request.admins
      .get()
      .then((data) => {
        setLoading(false);
        const admin = data.find((admin) => admin.id === providerInfo?.admin_id);
        if (admin) {
          const fullName = `${admin.name || ""} ${admin.surname || ""}`.trim();
          setCreatedByAdmin(fullName || admin.email);
        } else {
          setCreatedByAdmin("The reseller completed their own registration");
        }
      })
      .catch(({ message }) => {});
  };

  const generatePassword = () => {
    form.setFields([
      {
        name: "password",
        value: returnGeneratePassword(),
      },
    ]);
  };

  const validateBrandName = (rule, value, callback) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (
      (typeof value === "string" || typeof value === "number") &&
      regex.test(value)
    ) {
      callback();
      setDisabled(false);
    } else {
      callback("Brand name must include only letters or numbers");
      setDisabled(true);
    }
  };

  const getProfile = () => {
    Request.profile()
      .then((data) => {
        setProfile(data);
      })
      .catch((err) => {});
  };

  const getOptionsPartnerPackages = (message) => {
    Request.partner_packages
      .get({ query: JSON.stringify({ page: 1, limit: 1000 }) })
      .then((data) => {
        if (data?.rows) {
          const findDisabledPack = data?.rows?.find(
            (item) => partnerPackageId == item.id
          );
          if (findDisabledPack) {
            setCurrentPackg(findDisabledPack);
            formResellerType.setFields([
              {
                name: "partner_package_id",
                value: findDisabledPack?.id,
              },
            ]);
          }
          const options = data?.rows?.map((item) => ({
            label: `${item.name} ( Activations ${item.device_count} ) $${item.price} `,
            value: item.id,
            disabled: item.archived,
          }));

          if (message == "set field") {
            formResellerType.setFields([
              {
                name: "partner_package_id",
                value: options[options?.length - 1]?.value,
              },
            ]);
          }

          setPartnerPackagesList(options);
        }
      })
      .catch((error) => {
        console.error("Error fetching partner packages:", error);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    if (!providerInfo) {
      return;
    }

    if (values.country == providerInfo?.country) {
      delete values.country;
    }

    if (values.brand_name == providerInfo?.brand_name) {
      delete values.brand_name;
    }

    if (values.app_name == providerInfo?.app_name) {
      delete values.app_name;
    }

    if (values.email == providerInfo?.email) {
      delete values.email;
    }

    if (values.phone_number == providerInfo?.phone_number) {
      delete values.phone_number;
    }

    const formattedValues = {
      ...values,
      id: Number(theProviderId),
      do_not_allow_login: values.do_not_allow_login,
    };

    delete formattedValues.partner_key;

    const filteredObj = Object.keys(formattedValues).reduce((acc, key) => {
      const value = formattedValues[key];
      if (value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    Modal.confirm({
      title: "Do you want to change these info?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        Request.provider
          .put(filteredObj)
          .then(() => {
            setLoading(false);
            notification.success({ description: "Reseller info updated!" });
            getProviderInfo();
            setGetResellers(true);
          })
          .catch(({ message }) => {
            setLoading(false);
            notification.error({ message });
          });
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const onBlock = () => {
    if (theProviderId) {
      Request.provider
        .block({ id: theProviderId, blocked: !blockReseller })
        .then((res) => {
          notification.success({ description: res });
          setBlockReseller(!blockReseller);
        })
        .catch((err) => notification.error({ description: err?.message }));
    }
  };

  const onResetDevices = () => {
    if (theProviderId) {
      Request.deactivate_provider_devices({
        provider_id: Number(theProviderId),
      })
        .then((res) => {
          setDeactivatedAllDevices(true);
          notification.success({ description: res });
        })
        .catch((err) => notification.error({ description: err?.message }));
    }
  };

  const fields = {
    id: {
      type: "INTEGER",
      rules: [{ required: false, message: "" }],
    },

    brand_name: {
      type: "STRING",
      rules: [
        { required: false, message: "" },
        { validator: validateBrandName },
      ],
    },
    app_name: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },

    partner_key: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },

    email: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },

    password: {
      type: "STRING",
      rules: [
        { required: false, message: "" },
        {
          min: 8,
          message: "Password must be minimum 8 characters.",
        },
      ],
    },

    is_partner: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    is_seller: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },

    partner_package_id: {
      type: "NUMBER",
      rules: [{ required: false, message: "" }],
    },
    phone_number: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
    country: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
    do_not_allow_login: {
      type: "BOOLEAN",
      rules: [{ required: false, message: "" }],
    },
  };

  console.log("providerInfo", providerInfo);

  return (
    <>
      <div>
        <p className="br-lable">
          <span style={{ fontWeight: 400, marginRight: "5px" }}>
            Created by:{" "}
          </span>
          <span style={{ color: "#000" }}>{createdByAdmin}</span>
        </p>
        <p className="br-lable">
          <span style={{ fontWeight: 400, marginRight: "5px" }}>
            Last online date:{" "}
          </span>
          <span style={{ color: "#000" }}>
            {providerInfo?.online_date ? (
              <TableDateHHMMSS date={providerInfo?.online_date} />
            ) : (
              "N/A"
            )}
          </span>
        </p>

        {providerInfo?.parent_id && (
          <p className="br-lable">
            Parent ID:{" "}
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => setOnParentId(true)}
            >
              {providerInfo?.parent_id}
            </Button>
          </p>
        )}
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="reseller-info-form"
      >
        <Form.Item
          label="Brand name"
          name="brand_name"
          rules={fields.brand_name.rules}
        >
          <Input placeholder="brand name" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={fields.email.rules}>
          <Input placeholder="email" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item
          label="App name"
          name="app_name"
          rules={fields.app_name.rules}
        >
          <Input placeholder="App name" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone_number"
          rules={fields.phone_number.rules}
        >
          <Input placeholder="Phone number" style={{ maxWidth: 500 }} />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const { password } = form.getFieldsValue();
            return (
              <Form.Item
                label="Password"
                name="password"
                rules={fields.password.rules}
              >
                <Input.Password
                  allowClear
                  placeholder="Password"
                  style={{ maxWidth: 500 }}
                  visibilityToggle={{ visible: true }}
                  addonAfter={
                    <Tooltip title="Generate password">
                      <TransactionOutlined
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "18px",
                          color: "#258ffb",
                          padding: 0,
                        }}
                        onClick={generatePassword}
                      />
                    </Tooltip>
                  }
                  addonBefore={
                    password && (
                      <CopyOutlined
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "18px",
                          color: "#258ffb",
                          padding: 0,
                        }}
                        onClick={() => copyText(password)}
                      />
                    )
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="Partner key"
          name="partner_key"
          rules={fields.partner_key.rules}
        >
          <Input
            placeholder="partner_key"
            style={{ maxWidth: 500 }}
            disabled
            addonBefore={
              <CopyOutlined
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  color: "#258ffb",
                  padding: 0,
                }}
                onClick={() => copyText(providerInfo?.partner_key)}
              />
            }
          />
        </Form.Item>
        <Form.Item label="Country" name="country" rules={fields.country.rules}>
          <Select
            showSearch
            allowClear
            placeholder="Search by country"
            optionFilterProp="label"
            options={countriesOptions}
            style={{ maxWidth: "500px" }}
          />
        </Form.Item>
        {(providerInfo?.parent_id && !providerInfo?.is_partner) ||
        !profile?.permission?.edit_reseller ? (
          <></>
        ) : (
          <Form.Item>
            <p style={{ marginBottom: 0 }} className="br-lable">
              Reseller type:
              {!providerInfo?.is_partner ||
              !providerInfo?.partner_package_id ? (
                <Button
                  type="link"
                  onClick={() => setIsResellerTypeDrawer(true)}
                >
                  {resellerType} <RightOutlined />
                </Button>
              ) : (
                <span> {resellerType}</span>
              )}
            </p>
            {resellerType !== "Reseller" && (
              <p className="br-lable">
                Package:{" "}
                {!providerInfo?.is_partner &&
                !providerInfo?.partner_package_id ? (
                  <Button
                    type="link"
                    onClick={() => setIsResellerTypeDrawer(true)}
                  >
                    <TableText
                      style={{ color: "#1890ff" }}
                      text={`${currentPackg?.name} ( Activations ${currentPackg?.device_count} ) $${currentPackg?.price} `}
                    />

                    <RightOutlined />
                  </Button>
                ) : (
                  <span>
                    {" "}
                    {`${currentPackg?.name} ( Activations ${currentPackg?.device_count} ) $${currentPackg?.price} `}
                  </span>
                )}
                {currentPackg?.archived && (
                  <p style={{ color: "#7c5509", fontSize: "12px" }}>
                    <WarningOutlined /> This package archived!
                  </p>
                )}
              </p>
            )}
          </Form.Item>
        )}

        <Form.Item
          name="do_not_allow_login"
          label="Do not allow login"
          rules={fields.do_not_allow_login.rules}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item></Form.Item>
        {providerInfo?.parent_id && <Form.Item></Form.Item>}
        <Form.Item>
          <div style={{ display: "flex", gap: "10px" }}>
            <Space direction="horizontal">
              {profile?.permission_level && (
                <ConfirmComponent
                  confirm={onResetDevices}
                  title={`Are you sure to deactivated devices this reseller ?`}
                  value="Deactivated Devices"
                  type="danger"
                />
              )}
              {(profile?.permission_level ||
                profile?.permission?.manage_reseller_block) && (
                <ConfirmComponent
                  confirm={onBlock}
                  title={`Are you sure to ${
                    blockReseller ? "Unblock" : "Block"
                  } this reseller ?`}
                  value={blockReseller ? "Unblock" : "Block"}
                  type={blockReseller ? "primary" : "danger"}
                />
              )}
            </Space>

            <Button
              type="primary"
              onClick={() => form.submit()}
              // loading={loading}
              disabled={disabled}
            >
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

      {isResellerTypeDrawer && (
        <ResellerTypeDrawer
          open={isResellerTypeDrawer}
          onClose={() => setIsResellerTypeDrawer(false)}
          providerInfo={providerInfo}
          getProviderInfo={getProviderInfo}
          form={formResellerType}
          partnerPackagesList={partnerPackagesList}
          getOptionsPartnerPackages={getOptionsPartnerPackages}
          currentPackg={currentPackg}
          setPartnerPackageId={setPartnerPackageId}
          profile={profile}
        />
      )}

      {onParentId && providerInfo?.parent_id && (
        <ResellerInfoDrawer
          resellerId={providerInfo?.parent_id}
          open={onParentId}
          onClose={() => setOnParentId(false)}
        />
      )}
    </>
  );
}
