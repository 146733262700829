import React, { useState, useEffect } from "react";
import { Modal, Spin, Empty } from "antd";
import Request from "../../../Requests";

export default function DevicesCount({ isOpen, onCancel, theProviderId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    Request.device_count({ provider_id: theProviderId })
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }
  return (
    <Modal
      title="Devices Count"
      open={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <div>
        {loading ? (
          <div className="loader_desc-list">
            <Spin spinning={loading}>
              {" "}
              <Empty description={false} />
            </Spin>
          </div>
        ) : (
          <ul className="desc-list">
            <li>
              <span>Active Devices:</span>
              <span>{data?.payed}</span>
            </li>
            <li>
              <span>Trial Devices:</span>
              <span>{data?.is_trial}</span>
            </li>
          </ul>
        )}
      </div>
    </Modal>
  );
}
