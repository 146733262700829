import { useState } from "react";
import { Tabs, Button } from "antd";
import ResellerDevice from "./ResellerDevice";
import Deactivated from "./Deactivated";
import DevicesCount from "./DevicesCount";

export default function DevicesTab({ theProviderId, deactivatedAllDevices }) {
  const [activeKey, setActiveKey] = useState("All");
  const [isOpen, setIsOpen] = useState(false);

  const items = [
    {
      key: "All",
      label: "All",
      children: (
        <ResellerDevice
          theProviderId={theProviderId}
          deactivatedAllDevices={deactivatedAllDevices}
        />
      ),
    },

    {
      key: "Deactivated history",
      label: "Deactivated history",
      children: <Deactivated theProviderId={theProviderId} />,
    },
  ];

  return (
    <>
      <div style={{ display: "flex" }}>
        <Button type="primary" onClick={() => setIsOpen(true)}>
          Get devices count
        </Button>
      </div>
      <Tabs
        items={items}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      />
      {isOpen && (
        <DevicesCount
          theProviderId={theProviderId}
          isOpen={isOpen}
          onCancel={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
